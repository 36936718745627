import {
  AfterViewInit,
  Component,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';

import {
  AppService,
  Project,
  ProjectsService,
  Team,
  TeamMemberRoles,
  TeamsService,
  ToastsService,
  UserRoles,
  UserService,
} from '../../../core';
import { AjaxDataSource } from '../../../core/services/ajax.datasource';
import { DataTableComponent } from '../../components/data-table/data-table.component';

@Component({
  selector: 'app-projects-listing',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent extends DataTableComponent implements AfterViewInit {

  loading = false;
  displayedColumns: Array<string> = ['name', 'key', 'created_at', 'team_id', 'action'];
  datasource: AjaxDataSource<Project>;
  defaultFilters: { q: string } = { q: '' };
  filterService: string = 'projectsService';
  filterStorage: string = '_filterAdminProjects';
  teamMap: Map<number, string>;
  isTeamHead = false;

  constructor(
    private appService: AppService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private toastsService: ToastsService,
    private projectsService: ProjectsService,
    private teamsService: TeamsService,
    private userService: UserService
  ) {
    super();
    this.appService.title = 'pages.projects.list';
    this.appService.active = 'projects';
    this.datasource = new AjaxDataSource<Project>();
    this.teamMap = new Map<number, string>();
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.loadTeamNames();
    this.checkIfMemberIsTeamHead();
  }

  checkIfMemberIsTeamHead(): void {
    this.userService.currentUser.subscribe(user => {
      if (user.role === UserRoles.ADMIN) {
        this.isTeamHead = true;
      } else {
        const isHeadInAnyTeam = user.members?.some(member => member.role === TeamMemberRoles.HEAD);
        this.isTeamHead = isHeadInAnyTeam || false;
      }
    });
  }

  loadTeamNames(): void {
    this.teamsService.fetchTeams().subscribe(teams => {
      this.teamMap = teams.list.reduce((map: Map<number, string>, team: Team) => {
        map.set(team.id, team.name);
        return map;
      }, new Map<number, string>());
    });
  }

  reload(): void {
    this.updateDataSource(this.projectsService.fetchProjects(this.ajaxParams.getHttpParams()));
  }
}
